<template>
    <v-row>
      <v-col
        cols="12"
        lg="8"
        md="12"
      >
      <div class="mb-2">
          <v-footer
            tile
            flat
            :height="this.$vuetify.breakpoint.xs ? 60 : 80"
            class="font-weight-medium shadow-md"
            :class="$vuetify.breakpoint.smAndUp ? 'pr-2' : ''"
            :color="$vuetify.theme.dark ? 'dark' : 'white'"
            :dark="$vuetify.theme.dark"
            :inset="true"
            >
            <v-col
                class="text-left py-0"
                cols="12"
            >
                <v-row class="py-0" v-if="this.$vuetify.breakpoint.smAndUp">
                <!-- <v-col
                    cols="12"
                    sm="5"
                    :class="this.$vuetify.breakpoint.xs ? 'mt-2' : ''"
                    class="py-0 px-0 d-flex align-center"
                    :style="this.$vuetify.breakpoint.xs ? 'justify-content: flex-end' : 'justify-content: flex-end'"
                >
                <div class="d-flex" style="position: relative;">
                    <div>
                        <h5>
                            {{ this.loggedInUser.userDetails.displayName }}
                        </h5>
                    </div>
                        <div class="flex-grow-1 ml-3">
                          <v-btn @click="toggleTextField" key="searchButton" color="grey" class="white--text search-button" small>
                          <div>Search</div>
                          <v-icon right dark>mdi-account-search</v-icon>
                        </v-btn>
                        </div>
                        <v-divider v-if="!this.$vuetify.breakpoint.xs" vertical class="ml-1 mr-1" />

                        <v-autocomplete
                          v-model="selectedUser"
                          :loading="loading"
                          :items="getSearchedUsers"
                          :search-input.sync="search"
                          item-text="text"
                          item-value="value"
                          cache-items
                          hide-no-data
                          :style="textFieldStyle" 
                          outlined
                          clearable 
                          hide-details 
                          ref="searchField" 
                          key="searchField" 
                          autofocus 
                          class="search-field pa-0" 
                          dense 
                          filled 
                          placeholder="Enter displayname"
                          append-icon="mdi-close-circle"
                          @click:append="toggleTextField"
                        ></v-autocomplete>             
                    </div>
                </v-col> -->
                <v-col
                    cols="9"
                    class="py-0 px-0 d-flex align-center"
                    style="justify-content: space-evenly;"
                >
                    <div>
                      <h6>
                        {{ this.loggedInUser.userDetails.displayName }}
                      </h6>
                    </div>
                    <div style="display: flex;">
                      <span>
                        <h6>{{ !this.$vuetify.breakpoint.xs ? 'Position' : 'Pos:' }}
                          <span>{{ getGameweekResult.position == 0 ? 'N/A' : getGameweekResult.position }}</span>
                        </h6>
                      </span>
                      <span class="icon-wrap ml-1">
                        <v-icon :color="movementColour" class="white--text mr-n1 pb-2">{{ positionString }}</v-icon>
                        <h6 :style="`color: ${movementColour}`">{{ getGameweekResult.positionChange == 0 ? '&nbsp;(No Change)' : getGameweekResult.positionChange }}</h6>
                      </span>
                    </div>
                    <div class="d-flex">
                        <h6>Points&nbsp;{{ getGameweekResult.weekpoints }}</h6>
                    </div>
                </v-col>
                <v-col
                    cols="3"
                    class="py-0 pl-0"
                >
                    <v-select
                        id="matchdaySelect"
                        :items="previousGameweeks"
                        label="Matchday"
                        @change="updateGameweek"
                        v-model="selectedPredictions"
                    />
                </v-col>
                </v-row>
                <v-row v-else class="py-0 ml-2" no-gutters>
                  <v-col cols="9" class="pa-1 ma-0">
                    <v-row no-gutters>
                      <v-col>{{ this.loggedInUser.userDetails.displayName }}</v-col>
                      <v-col>Points&nbsp;{{ getGameweekResult.weekpoints }}</v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col>Position&nbsp;{{ getGameweekResult.position == 0 ? 'N/A' : getGameweekResult.position }}</v-col>
                      <v-col>
                        <span class="icon-wrap">
                        <span :style="`color: ${movementColour}`">{{ getGameweekResult.positionChange == 0 ? '(No Change) –' : getGameweekResult.positionChange }}</span>
                        <v-icon :color="movementColour" class="white--text ml-n1">{{ positionString }}</v-icon>
                        </span>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="3" class="pa-1 ma-0">
                    <v-select
                      class="mt-0"
                      id="matchdaySelect"
                      :items="previousGameweeks"
                      label="Matchday"
                      @change="updateGameweek"
                      v-model="selectedPredictions"
                    />
                  </v-col>
                </v-row>
            </v-col>
            </v-footer>
        </div>
        <v-row>
          <v-col cols="12">
            <chart-card
            >
              <template slot="chart">
                <v-col cols="12">
                  <v-data-iterator
                    :items="previousPredictions"
                    :items-per-page.sync="itemsPerPage"
                    :page="page"
                    hide-default-footer
                  >
                    <template v-slot:default="props">
                      <v-row justify="center">
                        <v-col
                          v-for="(item, index) in props.items"
                          :key="index"
                          cols="12"
                          lg="8"
                          class="pa-0 mb-2"
                        >
                          <list-card-row-result
                            :id="`match-${index}`"
                            :ref="`match-${index}`"
                            :fixture="transformPrediction(item.fixtureEnt)"
                            :prediction="item"
                            :progressbar-color="item.progressColor"
                            :resultLabel="item.winningMargin.label"
                            :winnerLabel="item.matchResult"
                            footer-btn-color="default"
                            footer-btn-two="PROFILE"
                            footer-btn-color-two="default"
                            class="fixture"
                          />
                        </v-col>
                      </v-row>
                    </template>
                  </v-data-iterator>
                </v-col>
              </template>
            </chart-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        lg="4"
        md="12"
        v-if="false"
      >
        <base-card>
          <v-card-title>
            Previous Predictions
          </v-card-title>
          <v-card-text>
            <div>
              <v-select
                  id="matchdaySelect"
                  :items="previousGameweeks"
                  label="Matchday"
                  @change="updateGameweek"
                  v-model="selectedPredictions"
              />
            </div>
            <div v-for="prediction in previousPredictions" :key="prediction.id">
              <list-card-row-result
                :id="`prediction-${prediction.id}`"
                :ref="`prediction-${prediction.id}`"
                :fixture="transformPrediction(prediction.fixtureEnt)"
                :prediction="prediction"
                :resultLabel="prediction.winningMargin.label"
                :winnerLabel="prediction.matchResult"
                :summary="true"
                footer-btn-color="default"
                footer-btn-two="PROFILE"
                footer-btn-color-two="default"
                class="fixture mt-4"
              />
            </div>
          </v-card-text>
        </base-card>
      </v-col>
      <v-col
      cols="12"
      lg="4"
      md="12"
    >
      <base-card>
        <v-card-title>
          Insights
        </v-card-title>
        <v-card-text>
          <v-tabs v-model="tab">
            <v-tab>
              Cumulative
            </v-tab>
            <v-tab>
              Week Ranking
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item
            >
              <v-card flat>
                <v-card-subtitle>
                  <span v-if="selectedPredictions != 0">Percentage of players guessing correctly in game week {{ selectedPredictions }}                    
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                      </template>
                      <span><b><u>Correct result</u></b> is the % of players who correctly predicted the result.<br/><b><u>Correct margin</u></b> is the % of <i>those</i> players who also correctly predicted the margin.
                      </span>
                    </v-tooltip></span>
                  <span v-else>Available after gameweek 1</span>
                </v-card-subtitle>
                <v-card-text>
                  <v-simple-table>
                    <thead>
                      <tr>
                        <th class="pa-0" style="width: 150px; text-align: center;">
                          Fixture
                        </th>
                        <th class="pa-0" style="text-align: center;">
                          Correct Result
                        </th>
                        <th class="pa-0" style="text-align: center;">
                          Correct Margin
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                      v-for="prediction in previousPredictions"
                        :key="prediction.id"
                        :class="{'first-position': 2 === 1, 'own_position': 2 === loggedInUser.userDetails.id}"
                      >
                        <td class="fixture_col px-0" style="text-align: center;">
                          <span>
                            <v-img
                              :src="`/assets/gaa/teams/icons/${prediction.fixtureEnt.homeTeam.code}.WEBP`"
                              :alt="prediction.fixtureEnt.homeTeam.code"
                              contain
                              max-width="30"
                              class="img_border"
                            ></v-img>
                          </span>
                          <span class="ml-1 mr-1">{{ prediction.fixtureEnt.homeGoals }}-{{ prediction.fixtureEnt.homePoints }} </span>
                          <span class="mr-1 ml-1">{{ prediction.fixtureEnt.awayGoals }}-{{ prediction.fixtureEnt.awayPoints }}</span>
                          <span>
                            <v-img
                              :src="`/assets/gaa/teams/icons/${prediction.fixtureEnt.awayTeam.code}.WEBP`"
                              :alt="prediction.fixtureEnt.awayTeam.code"
                              contain
                              max-width="30"
                              class="img_border"
                            ></v-img>
                          </span>
                        </td>
                        <td class="pa-0" style="text-align: center;">
                          {{ formatNumber(prediction.fixtureEnt.correctResultPercentage) }}%
                        </td>
                        <td class="pa-0 ml-2" style="text-align: center;">
                          {{ formatNumber(prediction.fixtureEnt.correctMarginPercentage) }}%
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card>
                <v-card-subtitle v-if="selectedPredictions != 0">
                  Your overall performance in game week {{ selectedPredictions }}
                </v-card-subtitle>
                <v-card-subtitle v-else>
                  Available after game week 1 
                </v-card-subtitle>
                <v-card-text>
                  <v-data-table
                    id="leagueTable"
                    :headers="headers"
                    :items="getGameweekSnapshot.particpantResultSnapshotDtoList"
                    item-key="name"
                    sort-by="weekpoints"
                    sort-desc
                    :mobile-breakpoint="0"
                    class="elevation-1 table-one"
                    hide-default-footer
                    :header-class="'table-headers-word-wrap'"
                    :class="this.$vuetify.breakpoint.smAndDown ? 'hide-header' : ''"
                >
                    <template v-slot:item.name="{item}">
                        <b class="ml-4">{{ item.name }}</b>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </base-card>
    </v-col>
    </v-row>
  </template>
    <script>
    import ChartCard from '@/components/card/ChartCard'
    import ListCardRow from '@/components/card/listCard/ListCardRowSelection'
    import ListCardRowResult from '@/components/card/listCard/ListCardRowSelectionResult'
    import { mapGetters, mapActions } from 'vuex'
    import store from '@/store'
    import { gsap } from 'gsap';
    import _ from 'lodash';
  
    export default {
      name: 'History',
      metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'My History',
      },
      components: {
        ChartCard,
        ListCardRow,
        ListCardRowResult,
      },
      beforeRouteEnter(to, from, next) {
        // Check if the user is logged in or has a valid authentication token
        const isLoggedIn = store.getters.loggedInUser;
  
        // If the route requires authentication and the user is not logged in, redirect to the login page
        if (to.meta.requiresAuth && !isLoggedIn) {
          next('/login'); // Redirect to the login page
        } else {
          next(); // Continue to the requested route
        }
      },
      mounted(){
        this.selectedPredictions = this.getCurrentGameweek -1;
        if(this.$store.getters.getCompetitionId){
          this.fetchPastSelections({userId: this.loggedInUser.userDetails.id, competitionId: this.$store.getters.getCompetitionId});
        }
        this.fetchGameweekSnapshot({userId: this.loggedInUser.userDetails.id, gameweek: this.getCurrentGameweek-1, sport: this.$router.currentRoute.meta.sport.toUpperCase() });
      },
      data () {
        return {
          selections: [],
          tab: null,
          itemsPerPage: 6,
          page: 1,
          selectedPredictions: 'Last Week',
          showTextField: false,
          searchText: '',
          loading: false,
          items: [],
          search: null,
          selectedUser: null
        }
      },
      computed: {
        ...mapGetters(['loggedInUser', 'getCurrentGameweek', 'getPastSelections', 'getGameweekResult', 'getGameweekSnapshot', 'getSearchedUsers']),
        previousPredictions(){
          if(this.selectedPredictions){
            return this.$store.getters['getPastSelections'].filter(prediction => prediction.fixtureEnt.matchday == this.selectedPredictions)
          }
          return this.$store.getters['getPastSelections'].filter(prediction => prediction.fixtureEnt.matchday == this.$store.getters['getCurrentGameweek'])
        },
        previousGameweeks(){
          let highestNumber = this.$store.getters.getCurrentGameweek;
          let totalWeeks = [];
          for(let i=1; i<=highestNumber-2; i++){
              totalWeeks.push(i)
          }
          totalWeeks.push({ text: 'Last Week', value: this.$store.getters.getCurrentGameweek-1 });
          return totalWeeks;
        },
        positionString(){
          return this.arrowDisplay(String(this.$store.getters.getGameweekResult.positionChange));
        },
        movementColour(){
          if(!this.ifValueExists(this.getGameweekResult.positionChange)) { return ''};
          if (this.getGameweekResult.positionChange < 0) {
            return 'red'
          } else if (this.getGameweekResult.positionChange == '0') {
            return 'white'
          } else {
            return 'green'
          }
        },
        textFieldStyle() {
          return {
            width: this.showTextField ? '100%' : '0',
          };
        },
        headers(){
            let headers = [
                {
                    text: 'Player',
                    value: 'playerDisplayName', sortable: false
                },
                {text: 'Overall Position', value: 'position', sortable: false},
                // {text: 'Club', value: 'club'},
                {text: 'Gameweek Points', value: 'weekpoints', align: 'center', sortable: false},
            ]
            return headers;
        }
      },
      watch: {
        getCurrentGameweek(val){
          if(this.loggedInUser && this.loggedInUser.userDetails){
            this.fetchGameWeekSelection({userId: this.loggedInUser.userDetails.id, gameweek: val, competitionId: this.$store.getters.getCompetitionId});
            this.fetchGameweekResultForUser({userId: this.loggedInUser.userDetails.id, gameweek: val-1, sport: this.$router.currentRoute.meta.sport.toUpperCase()});
            this.fetchPastSelections({userId: this.loggedInUser.userDetails.id, competitionId: this.$store.getters.getCompetitionId});
            this.fetchGameweekSnapshot({userId: this.loggedInUser.userDetails.id, gameweek: val-1, sport: this.$router.currentRoute.meta.sport.toUpperCase()});
            this.selectedPredictions = val -1 ;
          }
        },
        search (val) {
          if(val && val !== this.select){
            this.querySelections(val);
          }
        },
        selectedUser(){
          if(this.selectedUser == null){
            this.fetchPastSelections({userId: this.loggedInUser.userDetails.id, competitionId: this.$store.getters.getCompetitionId});
          } else {
            //let selectedUserId = this.findUser(this.selectedUser);
            this.updateUserSelection(this.selectedUser);
          }
        }
      },
      methods: {
        ...mapActions(["fetchGameWeekSelection", "fetchPastSelections", "fetchGameweekResultForUser", "fetchGameweekSnapshot", "searchByUsername"]),
        arrowDisplay(change) {
            if(!this.ifValueExists(change)) { return ''};
            if(change.indexOf("-") > -1){
            return 'mdi-triangle-small-down'
            } else if(change == "0"){
            return ''
            } else {
            return 'mdi-triangle-small-up'
            }
        },
        ifValueExists(value){
            if (value !== null && value !== undefined) {
                return true;
            } else {
                return false;
            }
        },
        transformPrediction(fixture){
          return { 
            id: fixture.id,
            homeTeam: fixture.homeTeam.name,
            homeLogo: fixture.homeTeam.code,
            homeColour: fixture.homeTeam.colour,
            homeScore: `${fixture.homeGoals} ${fixture.homePoints}`,
            awayTeam: fixture.awayTeam.name,
            awayLogo: fixture.awayTeam.code,
            awayColour: fixture.awayTeam.colour,
            awayScore: `${fixture.awayGoals} ${fixture.awayPoints}`,
            date: new Date(fixture.date).toLocaleDateString(),
            time: new Date(fixture.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
            venue: fixture.venue,
            stage: fixture.stage,
            competition: fixture.competitionEnt.name,
            matchday: fixture.matchday,
            matchResult: fixture.matchResult
          }
        },
        updateGameweek(val){
          if(val == 'Last Week'){
            this.selectedPredictions = this.$store.getters['getCurrentGameweek'] -1;
            this.fetchGameweekResultForUser({userId: this.loggedInUser.userDetails.id, gameweek: this.$store.getters['getCurrentGameweek'], sport: this.$router.currentRoute.meta.sport.toUpperCase()});
            this.fetchGameweekSnapshot({userId: this.loggedInUser.userDetails.id, gameweek: this.$store.getters['getCurrentGameweek'], sport: this.$router.currentRoute.meta.sport.toUpperCase()});
          } else {
            this.fetchGameweekResultForUser({userId: this.loggedInUser.userDetails.id, gameweek: val, sport: this.$router.currentRoute.meta.sport.toUpperCase()});
            this.fetchGameweekSnapshot({userId: this.loggedInUser.userDetails.id, gameweek: val, sport: this.$router.currentRoute.meta.sport.toUpperCase()});
          }
        },
        toggleTextField() {
          this.showTextField = !this.showTextField;
          if(!this.showTextField){
            this.selectedUser = null;
          }
        },
        enterAnimation(el, done) {
          gsap.fromTo(el, { opacity: 0, width: 0 }, { opacity: 1, width: '100%', duration: 1, onComplete: done });
        },
        leaveAnimation(el, done) {
          gsap.to(el, { opacity: 0, width: 0, duration: 1, onComplete: done });
        },
        async querySelections (v) {
          if(v.length > 2){
            this.loading = true
            let response = await this.searchByUsername(v);
            this.loading = false;
            this.items = response.data;
          }
        },
        updateUserSelection(userId){
          this.fetchPastSelections({userId: userId, competitionId: this.$store.getters.getCompetitionId});
        },
        formatNumber(num) {
          return Number.isInteger(num) ? num : num.toFixed(2);
        }
      }
    }
    </script>
  
    <style scoped>

    .fixture_col {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .fixture {
      scroll-margin-top: 20vh;
    }

    .icon-wrap {
      align-content: center;
      display: flex;
      flex-wrap: wrap;
    }

    .search-button {
      z-index: 1;
    }

    .search-field {
      position: absolute;
      right: 0; /* Align to the right edge of the parent div */
      top: -20%; /* Align to the top of the parent div */
      width: 0; /* Start with a width of 0 */
      transition: width 0.5s ease; /* Smooth transition for width */
      overflow: hidden; /* Hide the overflow when the field is collapsed */
      background-color: grey;
      z-index: 2;
    }

    .table-headers-word-wrap th {
      white-space: normal;
    }

    .img_border {
      border-style: solid;
      border-width: 2px;
    }
</style>
  